<template>
  <div>
    <TableRittenRendabiliteitTemplate title="Rendabiliteit" :facturatie-id="facturatieId" />
  </div>
</template>

<script setup>
import { defineAsyncComponent, defineProps } from 'vue'

defineProps({
  facturatieId: Number,
})

const TableRittenRendabiliteitTemplate = defineAsyncComponent(() => import('@/components/Table/Ritten/RendabiliteitTemplate.vue'))
</script>
