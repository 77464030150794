<template>
  <div class="flex flex-col gap-4">
    <h2>Bedrijf #{{ routeFacturatieId }}</h2>

    <div class="flex flex-col lg:flex-row justify-between">
      <UITabs v-model="tab" :tabs="tabs" />
    </div>

    <router-view v-slot="{ Component }">
      <KeepAlive>
        <component :is="Component" v-bind="$attrs" :facturatie-id="routeFacturatieId" />
      </KeepAlive>
    </router-view>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import UITabs from '@/components/UI/Tabs.vue'

const route = useRoute()
const router = useRouter()

const tabs = ['Boekingen', 'Rendabiliteit']
const tab = ref(Math.max(0, tabs.findIndex(t => route.name.includes(`StatsDashboardBoekingen${t}`))))

watch(tab, () => {
  router.push({ name: `BedrijvenInfo${tabs[tab.value]}` })
})

const routeFacturatieId = computed(() => Number(route.params.facturatie_id))
</script>
